import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { MeetingFeedbackResultsModal, PageLayout } from '@components';
import { useModal, useQueryParamsState } from '@hooks';
import { MEETING_STATUSES } from '@shared/constants';
import { useSearchAllMeetings } from '@shared/react';
import { Meeting, NewMeeting } from '@shared/types';
import { Table, Space, Tag, Tooltip, Rate, message, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import authHeader from '../../../services/auth-header';
import EnrollmentModal from '../Users/EnrollmentModal';
import EditMeetingModal from './EditMeetingModal/EditMeetingModal';
import { Filters } from './Filters';

export type MeetingsSearchParams = {
  course: string;
  studentId: string;
  studentName: string;
  mentorId: string;
  mentorName: string;
  rangeStart: string;
  rangeEnd: string;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const MtgTag = (props: any) => {
  return (
    <Tag
      {...props}
      style={{ borderRadius: '15px', padding: '3px 10px', border: 'none' }}
      key={`tag-key`}
    >
      {props.children}
    </Tag>
  );
};

const MeetingStatus: React.FC<{ meeting: Meeting }> = ({ meeting }) => {
  const tags = [];

  // if date is in the future
  if (moment(meeting.startDate).isAfter(moment())) {
    tags.push(<MtgTag color="cyan"> Upcoming</MtgTag>);
    return <span>{tags}</span>;
  }

  // check for no show
  if (
    !meeting.attended.mentor &&
    !meeting.attended.student &&
    moment(meeting.startDate).isBefore(moment())
  ) {
    tags.push(
      <Tooltip title="Both participants did not attend">
        <MtgTag color="red">
          <CloseOutlined /> Both No Show
        </MtgTag>
      </Tooltip>
    );
  } else {
    if (
      !meeting.attended.mentor &&
      moment(meeting.startDate).isBefore(moment())
    )
      tags.push(
        <Tooltip title="Mentor did not attend">
          <MtgTag color="red">
            <CloseOutlined /> Mentor No Show
          </MtgTag>
        </Tooltip>
      );

    if (
      !meeting.attended.student &&
      moment(meeting.startDate).isBefore(moment())
    )
      tags.push(
        <Tooltip title="Student did not attend">
          <MtgTag color="red">
            <CloseOutlined /> Student No Show
          </MtgTag>
        </Tooltip>
      );
  }
  // if mentor attended and startDate is before now
  if (
    meeting.attended.mentor &&
    meeting.attended.student &&
    moment(meeting.startDate).isBefore(moment())
  ) {
    tags.push(
      <Tooltip title="Bot did attend">
        <MtgTag color="green">
          <CheckOutlined /> Both Attended
        </MtgTag>
      </Tooltip>
    );
  } else {
    if (meeting.attended.mentor && moment(meeting.startDate).isBefore(moment()))
      tags.push(
        <Tooltip title="Mentor did attend">
          <MtgTag color="green">
            <CheckOutlined /> Mentor Attended
          </MtgTag>
        </Tooltip>
      );
    if (
      meeting.attended.student &&
      moment(meeting.startDate).isBefore(moment())
    )
      tags.push(
        <Tooltip title="Student did attend">
          <MtgTag color="green">
            <CheckOutlined /> Student Attended
          </MtgTag>
        </Tooltip>
      );
  }

  if (
    moment(meeting.startDate).isBefore(moment()) &&
    meeting.mentorFeedback?.message
  ) {
    tags.push(
      <Tooltip title="Mentor has attended and provided feedback">
        <MtgTag color="green">
          <CheckOutlined /> Completed
        </MtgTag>
      </Tooltip>
    );
  } else {
    tags.push(
      <Tooltip title="No feedback provided by the mentor">
        <MtgTag color="grey">
          <CloseOutlined /> No feedback
        </MtgTag>
      </Tooltip>
    );
  }

  return <span>{tags}</span>;
};

const x = moment().subtract(1, 'month').toISOString();
const y = moment().add(1, 'month').toISOString();

const MeetingManagement = () => {
  const query = useQuery();
  const studentName = query.get('studentName');
  const mentorName = query.get('mentorName');
  const status = query.get('status');
  const course = query.get('course');
  const rangeStart =
    query.get('rangeStart') ??
    moment().subtract(1, 'month').format('YYYY-MM-DD');
  const rangeEnd =
    query.get('rangeEnd') ?? moment().add(1, 'month').format('YYYY-MM-DD');

  const [CoursesM, showCoursesM] = useModal(EnrollmentModal);

  const {
    data: allMeetings,
    isLoading: isLoadingAllMeetings,
    refetch: refetchAllMeetings,
  } = useSearchAllMeetings({
    // // studentId: userId ? userId : '',
    // studentName: username ? username : '',
    // rangeStart: moment().subtract(1, 'month').toISOString(),
    // rangeEnd: moment().add(1, 'month').toISOString(),

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    course: course,
    status: status !== null ? parseInt(status) : null,
    rangeStart: rangeStart,
    rangeEnd: rangeEnd,
    studentName: studentName ? studentName : '',
    mentorName: mentorName ? mentorName : '',
  });

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    studentName: studentName ?? '',
    mentorName: mentorName ?? '',
    rangeStart: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    rangeEnd: moment().add(1, 'month').format('YYYY-MM-DD'),
    status: status !== null ? parseInt(status) : null,
    course: course ?? '',
  });
  const [data, setData] = useState([]);

  const [FeedbackModal, showFeedbackModal] = useModal(
    MeetingFeedbackResultsModal,
    { meeting: {} }
  );

  const [pageSize, setPageSize] = useState(10); // Default page size is 10
  const pageSizeOptions = ['10', '50', '100', 'All'];

  const meetingStatuses = Object.entries(MEETING_STATUSES)
    .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
    .map(([key, value]) => value);

  const handlePageSizeChange = (value: any) => {
    if (value === 'All') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPageSize(allMeetings?.total); // Set page size to the total number of items
    } else {
      setPageSize(parseInt(value, 10));
    }
  };

  const canSortByRating = moment(searchParams.rangeEnd).isBefore(
    moment().subtract(1, 'day')
  );

  const getMeetingData = () => {
    axios
      .get('/api/meeting-new/all', {
        params: searchParams,
        headers: authHeader(),
      })
      .then((response) => {
        setData(response?.data?.meetings);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // useEffect(() => {
  //   getMeetingData();
  // }, [JSON.stringify(searchParams)]);

  const [showEditMeetingModal, setShowEditMeetingModal] = useState(false);
  const [meeting, setMeeting] = useState<NewMeeting>();

  const [loadingCSVData, setLoadingCSVData] = useState(false);

  const convertToCSV = (data: any) => {
    if (!data || data.length === 0) {
      message.error('No data to export');
      return;
    }

    const keys = [
      'Meeting Name',
      'Enrollment Title',
      'Mentor Name',
      'Student Name',
      'Student Phone Number',
      'Status',
      'Date',
      'Meeting ended?',
      'Mentor attended?',
      'Student attended?',
      'Student rate',
      'Mentor Engagement Rate',
      'Mentor Progress Rate',
      'Mentor Homework Rate',
      // 'Mentor StudentEngagement Explanation',
      // 'Mentor StudentProgress Explanation',
      // 'Mentor StudentHomework Explanation',
      // 'Current Status',
      // 'Action Plan',
      // 'Parent Help',
      'Meeting Link',
    ];

    const csvRows = [];

    csvRows.push(keys.join(','));

    function wrapText(text: string, maxLength: number) {
      if (!text) return '';
      const regex = new RegExp(`.{1,${maxLength}}`, 'g');
      return text.match(regex)?.join('\n');
    }

    function formatStatus(status: number) {
      switch (status) {
        case 0:
          return 'Scheduled';
        case 1:
          return 'Rescheduled';
        case 2:
          return 'Cancelled';
        case 3:
          return 'Attended';
        case 4:
          return 'Student attended';
        case 5:
          return 'Mentor attended';
        case 6:
          return 'No show';
        case 7:
          return 'Completed';
        case 8:
          return 'Completed by admin';
        case 9:
          return 'Pending review';
        case 10:
          return 'Uncompleted';
        case 11:
          return 'Paid';
        default:
          return 'Unknown';
      }
    }

    function formatBoolean(value: boolean) {
      return value ? 'Yes' : 'No';
    }

    function formatDate(str: string) {
      return moment(str).format('DD.MM.YYYY');
    }

    for (const row of data) {
      const values = [
        row.name,
        row?.enrollment?.title ?? 'not found',
        row?.mentor?.fullName ?? 'not found',
        row?.students[0]?.fullName ?? 'not found',
        row?.students[0]?.phoneNumber ?? 'not found',
        formatStatus(row.status),
        formatDate(row.startDate),
        formatBoolean(row.ended),
        formatBoolean(row?.attended?.mentor),
        formatBoolean(row?.attended?.student),
        row?.feedback[0]?.rate ?? 'not yet given',
        `${row?.mentorFeedback?.studentEngagement?.rate ?? 'not yet given'}`,
        `${row?.mentorFeedback?.studentProgress?.rate ?? 'not yet given'}`,
        `${row?.mentorFeedback?.studentHomework?.rate ?? 'not yet given'}`,
        // `${wrapText(row?.mentorFeedback?.studentEngagement?.explanation, 50)}`,
        // `${wrapText(row?.mentorFeedback?.studentProgress?.explanation, 50)}`,
        // `${wrapText(row?.mentorFeedback?.studentHomework?.explanation, 50)}`,
        // `${wrapText(row?.mentorFeedback?.currentStatus, 50)}`,
        // `${wrapText(row?.mentorFeedback?.actionPlan, 50)}`,
        // `${wrapText(row?.mentorFeedback?.parentHelp, 50)}`,
        row.meetingLink,
      ];

      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const downloadCSVData = () => {
    try {
      setLoadingCSVData(true);
      const csvContent = convertToCSV(allMeetings?.meetings);
      if (!csvContent) return;
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'meetings.csv';
      link.click();
      setLoadingCSVData(false);
    } catch (error) {
      // Handle error
      setLoadingCSVData(false);
      console.log('error: ', error);
    }
  };

  const editClickHandler = (record: NewMeeting) => {
    setShowEditMeetingModal(true);
    setMeeting(record);
  };

  return (
    <PageLayout headerTitle="All Meetings">
      <div style={{ marginBottom: '120px' }}>
        <Filters
          searchParams={searchParams}
          updateSearchParams={setSearchParams}
        />

        <Button
          type="primary"
          disabled={loadingCSVData}
          style={{
            border: '2px solid #000000',
            margin: '10px',
            float: 'right',
          }}
        >
          <div onClick={() => downloadCSVData()}>
            {!loadingCSVData ? 'Generate CSV' : 'Loading...'}
          </div>
        </Button>

        {isLoadingAllMeetings ? (
          <div
            style={{
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading...
          </div>
        ) : (
          // <Spinner />
          <Table
            style={{ marginTop: '30px' }}
            dataSource={allMeetings?.meetings}
            rowKey="_id"
            pagination={{
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                allMeetings?.total > 500
                  ? ['10', '50', '100']
                  : pageSizeOptions,
              onShowSizeChange: (current, size) => handlePageSizeChange(size),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            // pagination={{ pageSize: allMeetings?.total }}
            columns={[
              {
                title: 'Title',
                dataIndex: 'name',
                render: (val, record: any) => {
                  // show ellipsis if too long
                  const title =
                    val?.length > 30 ? `${val.substring(0, 30)}...` : val;
                  return (
                    <Tooltip title={val}>
                      <div
                        style={{
                          minHeight: '48px',
                          lineHeight: '48px',
                          fontWeight: 600,
                        }}
                      >
                        {title}
                      </div>
                    </Tooltip>
                  );
                },
              },
              {
                title: 'Date',
                dataIndex: 'startDate',
                sorter: (a, b) => {
                  return (
                    moment(a.startDate).valueOf() -
                    moment(b.startDate).valueOf()
                  );
                },
                render: (date) => (
                  <Tooltip title={moment(date).fromNow()}>
                    {moment(date).format('DD MMM HH:mm')}
                  </Tooltip>
                ),
              },
              {
                title: 'Student',
                dataIndex: 'students',
                render: (students) => (
                  <div
                    className="main-green-link"
                    onClick={() => showCoursesM({ userId: students[0]?._id })}
                  >
                    {students[0]?.fullName}
                  </div>
                ),
              },
              {
                title: 'Mentor',
                dataIndex: 'mentor',
                render: (mentor) => mentor?.fullName,
              },
              {
                title: 'Status',
                dataIndex: 'status',
                // render: (_, meeting) => <MeetingStatus meeting={meeting} />,
                render: (status) => <div>{meetingStatuses[status]}</div>,
              },
              {
                title: 'Rating',
                dataIndex: 'feedback',
                sorter: (a, b) => {
                  if (!canSortByRating) {
                    message.info({
                      content: `Sorting by rating won't work if the date range ends past today`,
                      key: 'canSortByRatingInfo',
                    });
                    return null;
                  }
                  return (
                    a.feedback[0]?.rating ?? 0 - b.feedback[0]?.rating ?? 0
                  );
                },

                render: (feedback) => {
                  if (!feedback?.length) return 'n/a';
                  const average =
                    feedback.reduce(
                      (acc: any, cur: any) => acc + cur.rating,
                      0
                    ) / feedback.length;
                  return (
                    <Rate disabled value={average} style={{ color: 'black' }} />
                  );
                },
              },
              {
                title: 'Actions',
                render: (text, record) => (
                  <Space size="middle">
                    <a
                      href={record?.meetingLink ?? ''}
                      className="main-green-link"
                    >
                      Join
                    </a>
                    <span
                      className="faux-link main-green-link"
                      onClick={() =>
                        showFeedbackModal({
                          meeting: record,
                        })
                      }
                    >
                      Feedback
                    </span>
                    {record?.enrollment?._id ? (
                      <span
                        className="faux-link main-green-link"
                        onClick={() => editClickHandler(record)}
                      >
                        Edit
                      </span>
                    ) : (
                      <span style={{ color: 'grey' }}>
                        Enrollment not found
                      </span>
                    )}
                  </Space>
                ),
              },
            ]}
          />
        )}
      </div>

      {CoursesM}
      {FeedbackModal}

      {showEditMeetingModal && (
        <EditMeetingModal
          meeting={meeting!}
          onSave={refetchAllMeetings}
          isModalOpen={showEditMeetingModal}
          setModalOpen={setShowEditMeetingModal}
        />
      )}
    </PageLayout>
  );
};

export default MeetingManagement;
